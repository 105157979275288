export function classNames(classes: {}) {
  return Object.entries(classes)
      .map(([key, value]: [string, any]) => (value ? key : ''))
      .join(' ');
}

export function classNamesWithBase(baseClasses: string, classes: {}): string {
  return baseClasses + ' ' + Object.entries(classes)
      .map(([key, value]: [string, any]) => (value ? key : ''))
      .join(' ');
}

export function baseClassName(...baseClasses: string[]): (classes: {}) => string {
  const base: string = baseClasses.join(' ');
  return (classes: {}): string => {
    return [base, classNames(classes)].join(' ');
  };
}
